import React, { useEffect, useState } from "react";
import DateSelection from "../components/DateSelection/DateSelection";
import tadobaLogo from "../assets/tadoba-logo.png";
import GateSelection from "../components/GateSelection/GateSelection";
import { RootState, AppDispatch } from '../Redux-Store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getDayTime } from "../Redux-Store/actions/DayTimeAction";
import ShowMessage from '../components/ShowMessage/ShowMessage';
import { KioskGateAction } from '../Redux-Store/actions/KioskGateAction';
import TimeSlotComponent from "../components/TimeSlotComponent/TimeSlotComponent";

// const getNextSevenDays = () => {
//   const today = new Date();
//   const daysArray = [];
//   for (let i = 0; i < 7; i++) {
//     const nextDate = new Date(today);
//     nextDate.setDate(today.getDate() + i);
//     const day = nextDate.toLocaleString("en-US", { weekday: "short" });
//     const date = nextDate.toLocaleString("en-US", {
//       day: "numeric",
//       month: "short",
//       year: "numeric",
//     });
//     daysArray.push({ day, date, fullDate: nextDate });
//   }
//   return daysArray;
// };

const DateSelectionPage = () => {
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState<'success' | 'error'>('success');
  const [displayGates, setDisplayGates] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentDate, setCurrentDate] = useState<String>();
  const [getFetched, setGetFetched] = useState(false);



  const { dayTime, message, error } = useSelector((state: RootState) => state.dayTime);
  const { gates, loading, } = useSelector((state: RootState) => state.gates);
  const { kiosk_id } = useSelector((state: RootState) => state.login)
  console.log("gates", gates)
  console.log("kiosk_id11", kiosk_id)


  useEffect(() => {
    dispatch(getDayTime());
  }, [dispatch]);

  useEffect(() => {
    if(gates.length!==0){
      setGetFetched(true);
    }
    
  }, [gates]);

  // useEffect(() => {
  //   if (message) {
  //     setErrorMessage(message);
  //     setMessageType('success');
  //     setIsShow(true);
  //   } else if (error) {
  //     setErrorMessage(error);
  //     setMessageType('error');
  //     setIsShow(true);
  //   }
  // }, [message, error]);

  // useEffect(() => {
  //   let timer;
  //   if (isShow) {
  //     timer = setTimeout(() => {
  //       setIsShow(false);
  //     }, 1500);
  //   }
  //   return () => clearTimeout(timer);
  // }, [isShow]);

  useEffect(() => {
    if (dayTime.length > 0) {
      const initialDate = new Date(dayTime[0].api_date);
      setSelectedDate(initialDate);
      setCurrentDate(dayTime[0].api_date)
      handleDateClick(dayTime[0].api_date); // Fetch gates for the first date
    }
  }, [dayTime]);

  const handleDateClick = (apiDate: string) => {
    localStorage.setItem('selectedDate', apiDate)
    const kiosk_id_local = localStorage.getItem("kiosk_id");
    dispatch(KioskGateAction({ "kiosk_id": kiosk_id_local, "current_date": apiDate }))
    
    setErrorMessage(message);
    setMessageType('success');
    // setIsShow(true);
  };


  const handleGateSelect = (gateName) => {
    localStorage.setItem('selectedGate', gateName);

  }

  const isActive = (date: Date) => {
    if (!selectedDate) return false;
    return selectedDate.toDateString() === date.toDateString(); // Compare dates
  };

  return (
    <>
      <main>
        <section className="container">
          <div className="row border-bottom my-2 py-1">
            <div className="col-md-10">
              <h2>Tadoba National Park</h2>
            </div>
            <div className="col-md-2">
              <img src={tadobaLogo} alt="Tadoba National Park" width={70} />
            </div>
          </div>
          <div className="row mt-3 stickyTop">
            <h2 className="title mb-4">Tap On Date To Check Availability</h2>
            {dayTime.map((d, index) => {
              const dateObj = new Date(d.api_date); // Create Date object
              const isActiveDate = isActive(dateObj);
              const cardStyle = {
                backgroundColor: isActiveDate ? 'blue' : 'red',
                cursor: 'pointer'
              };
              return (
                <DateSelection
                  key={index}
                  day={d.day}
                  date={d.date.split('-').join(' ')}
                  isActive={isActiveDate}
                  style={cardStyle}
                  onClick={() => {
                    handleDateClick(d.api_date);
                    setSelectedDate(dateObj); // Update selected date
                    setCurrentDate(d.api_date)
                  }}
                />
              );
            })}
          </div>

          <div className="row mt-4">
            <div className="col-md-7">
              <h2 className="title mb-2 mt-3">Tap on Gate to Book Tickets</h2>
            </div>
            <div className=" col-12 col-md-5 ">
              <TimeSlotComponent />
            </div>
          </div>
          <div className="row">
          {
               (gates.every(gate => gate.available_seats === 0) && getFetched) ? (<div style={{fontSize:20, fontWeight:500,display:"flex", justifyContent: "center", alignItems:"center", color:'red', marginTop: 20}}>Gates are not available</div>) :
                gates && gates.map((gatesName) => {
                  return (
                    gatesName.available_seats === 0 ? '' :
                      (
                        <GateSelection
                          key={gatesName.gate_id}
                          gateId={gatesName.gate_id}
                          selectedDate={currentDate}
                          status={gatesName.gate_status}
                          gateName={gatesName.gate_name}
                          availableStatus={gatesName.gate_status === 'Open' ? 'Available' : "Gate Closed"}
                          avaialableCount={gatesName.available_seats}
                          onClick={() => handleGateSelect(gatesName.gate_name)}
                        />
                      )
                  );
              })
            }
 
          </div>
        </section>
      </main>
{/* 
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={messageType}
        open={isShow}
      /> */}
    </>
  );
};

export default DateSelectionPage;
