import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface confirmBookingDetailsParams{
    vehicle_schedule_id: number;
    mobile_number: any;
    no_of_seats: number
    booking_status: string,
    total_amount:any
    visitors: any
}

export const ConfirmBookingDetails = createAsyncThunk(actionTypes.CONFIRM_BOOKING_DETAILS, async ( params: any, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-confirm-ticket-booking', params);
      console.log('response in confirm ticket booking', response);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });