
export const actionTypes = {
KIOSK_LOGIN :'kiosk_login',
VERIFY_NUMBER: 'verify-number',
Verify_OTP:"verify_otp",
GET_DAY_TIME:'get_day_time',
GET_VEHICLES : 'get_vehicle',
LOGIN:'login',
CONFIRM_BOOKING_DETAILS: 'confirm_booking_details',
CONFIRM_TICKET: 'confirm_ticket',
CANCEL_BOOKING:'cancel_booking'
}