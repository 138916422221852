import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import mahaForestLogo from "../assets/mahaforestdept-logo.png";
import nicLogo from "../assets/nic.png";
import { RootState, AppDispatch } from '../Redux-Store/store';
import {kioskLogin}from '../Redux-Store/actions/KioskAction';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import getMAC from "getmac";
import AdminLogin from "./adminLogin";
type Props = {};

const Home = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const kiosk = useSelector((state: RootState) => state);
  console.log("users", kiosk)


  const navigate = useNavigate()

  const [loginScreen, setLoginScreen] = useState(false)



  const handleAdminLogin = () =>{
    navigate('/adminLogin')
    // setLoginScreen(true)
    
  }

  return (
    <>
      <main className="bgMain">
        <div className="container">
          <div className="row justify-content-center align-items-center height100">
            <div className="col-md-9 align-self-center">
              <div className="card card-transparancy">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      src={mahaForestLogo}
                      alt="Maharashtra Forest Logo"
                      width={160}
                    />
                         </div>

                    {/* {
                      !loginScreen?  */}
                      <>
                      <div className="text-center">
                      <h1 className="headingLight mt-3">Tadoba National Park</h1>
                      <h2 className="headingBold">Book Safari Tickets</h2>
               
                    <div className="d-flex justify-content-center mt-4 gap-3">
                      <Link
                        to={"/DateSelection"}
                        className="btnPrimary btnPadding26"
                      >
                        Book Tickets
                      </Link>
                      <Link
                        to={"/resendTicket"}
                        className="btnSecondary btnPadding26"
                      >
                        Resend Tickets
                      </Link>
                    </div>
                    </div>
                    </>
                  
                    {/* :
                    <div className="text-center">
                    <AdminLogin/>
                    </div>
                    } */}
               
                </div>
                <div className="card-footer bg-white text-center">
                  <p className=" textLight">Powered by</p>
                  <img
                    src={nicLogo}
                    alt="National Informatics Center (NIC)"
                    width={100}
                    onDoubleClick={handleAdminLogin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
