import React, { useState, useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider , ErrorMessage} from 'formik';
import ShowMessage from "../components/ShowMessage/ShowMessage";
import { UseSelector,useDispatch } from "react-redux";
import { RootState, AppDispatch } from '../Redux-Store/store';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "../components/ValidationPageComponent/PageHeader";
import PageFooter from "../components/ValidationPageComponent/PageFooter";



type Props = {

};

const ResendTicket = (Props) => {

  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)
  const [open,setOpen] =useState(false)
  const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
const [errorMessage, setErrorMessage] = useState('');
const [mobileNumber, setMobileNumber] = useState('');
const [messageType, setMessageType] = useState<'success' | 'error'>('success')
  const navigate = useNavigate()
  const dispatch :AppDispatch =useDispatch()
  const { mobileVerification, isLoading, message ,is_error} = useSelector((state: RootState) => state.mobileVerification);
console.log("mobileVerification", mobileVerification)

  interface FormValues {
    numberField: string;
  }

  const resendTicketSchema= Yup.object({
    numberField: Yup.string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed') // Ensure only numbers are allowed
    .max(10, 'Mobile number must be at most 15 digits')
    .required('WhatsApp mobile number is required'),
  })


  const backtoSelectionPage = () =>{
    navigate('/DateSelection')
  }




  const formik= useFormik<FormValues>({
    initialValues: {
      numberField:""
    },
    validationSchema: resendTicketSchema,
    onSubmit:(value)=>{
      console.log(value)
    }
  })



  const { errors, touched, handleSubmit, getFieldProps } = formik;

  
  return (
    <>

{/* <section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader /> */}

<section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader />
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <form action="" className="w-50" onSubmit={handleSubmit}>
            <div className="input-group input-group-lg flex-nowrap">
              <input
                type="text"
                className="form-control"
                placeholder="Enter WhatsApp Mobile Number"
                aria-label="WhatsApp Mobile Number"
                aria-describedby="addon-wrapping"
                {...getFieldProps('numberField')}
              />
              <span className="input-group-text bg-white" id="addon-wrapping">
                <IoLogoWhatsapp className="textSuccessDark" />
              </span>
            </div>
            {touched.numberField && errors.numberField ? (
                      <div style={{ color: "red" }}>
                        {errors.numberField}
                      </div>
                    ) : null}

            <div className="mt-4">
              <button
                className="btnPrimary btn-lg w-100"
                type="submit"
              >
          Resend Tickets
              </button>
              <button
              onClick={backtoSelectionPage}
                className="btnSecondary btn-lg w-100 linkbtn-block mt-3"
              >
              Back
              </button>
            </div>
          </form>
        </div>
      </div>
      <PageFooter />
          </div>
        </div>
      </div>
    </section>
      {/* <PageFooter />
          </div>
        </div>
      </div>
    </section> */}
    <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={messageType}
        open={isShow}
      />
    </>
  );
};

export default ResendTicket;
