import { createBrowserRouter } from "react-router-dom";
import App from "../src/App";
import Home from "../src/pages/home";
import DateSelectionPage from "../src/pages/DateSelection";
import ValidateMobile from "../src/pages/validateMobile";
import OtpVerification from "../src/pages/otpVerification";
import VehicleSelection from "../src/pages/vehicleSelection";
import ConfirmTickts from "../src/pages/confirmTickts";
import ResendTicket from "./pages/resendTicket";
import MobileNumber from "./components/MobileNumber/MobileNumber";
import AdminLogin from '../src/pages/adminLogin';
import ProtectedRoute from '../src/ProtectedRoutes'
import ProcessPayment from './pages/ProcessPayment'
import PaymentSuccess from './pages/PaymentSuccess'
import PaymentFailed from './pages/PaymentFailed'
import PayementStatus from "./pages/PayementStatus";
import { Navigate, useRoutes } from 'react-router-dom';



    const isLogged = localStorage.getItem('isLogged');
    console.log('isLogged', isLogged);

    const routes =()=> [
        { path: '/', element : <Home /> },
        { path: '/DateSelection', element: <DateSelectionPage /> },
        { path: '/validateMobile', element: <ValidateMobile /> },
        { path: '/otpVerification', element: <OtpVerification /> },
        { path: '/vehicleSelection', element: <VehicleSelection /> },
        { path: '/confirmTicket', element: <ConfirmTickts /> },
        { path: '/validateNumber', element: <MobileNumber /> },
        { path: '/resendTicket', element: <ResendTicket /> },
        { path: '/adminLogin', element: <AdminLogin /> },
        { path: '/processPayment', element: <ProcessPayment /> },
        { path: '/PaymentSuccess', element: <PaymentSuccess /> },
        { path: '/paymentFailed', element: <PaymentFailed /> },
        { path: '/paymentStatus', element: <PayementStatus /> },
        // { path: '/admin-login', element: <AdminLogin /> },
      ];
      export default routes;