import React from "react";
import { RiAncientGateFill } from "react-icons/ri";
import { Link } from "react-router-dom";

type Props = {
  status: string;
  gateName: string;
  gateId : String;
  selectedDate : String;
  availableStatus: String;
  avaialableCount: String;
  onClick: () => void;
};

const GateSelection = ({
  status,
  gateId,
  selectedDate,
  gateName,
  availableStatus,
  avaialableCount,
  onClick
}: Props) => {
  const isAvailable = status && availableStatus;
  const cardClass = isAvailable ? "available" : "notAvailable";
  const badgeClass = isAvailable ? "bg-success" : "bg-danger";
  return (
    <div className="col-3 text-center gateBlock my-2">
      <div className={`card ${cardClass} h-100`}>
        <div className="card-body">
          <RiAncientGateFill className="textSecondary icon2x" />
          <h6 className="my-2" style={{ fontWeight: 'bold' }}>{status}</h6>
          <h5 className="textPrimary">{gateName}</h5>
        </div>
        <div className="card-footer border-0">
        
          <span
            className={`badge rounded-pill mt-2 mb-2  ${badgeClass} p-3 text-small `}
            style={{ width: '130px' }}
          >
             {availableStatus}
             {/* { avaialableCount > '0' && */}
            <Link to={"/validateMobile"} state={ {"gateId":gateId,"selectedDate":selectedDate}} className="stretched-link text-white  ms-1"  onClick={onClick}>
            {avaialableCount}
            </Link>
             
          </span>
        </div>
      </div>
    </div>
  );
};

export default GateSelection;
