import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface VerifyMobileParams {
  mobile_number: string;
}

interface varifyOtpParams{
  mobile_number: string;
  otp: string

}

export const VerifyMobileAction = createAsyncThunk(actionTypes.VERIFY_NUMBER, async ( params: VerifyMobileParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-get-whats-app-number', params);
      console.log('response of login', response);
      // HandleExceptionWithSecureCatch(response);
  
    //   if (response.data.data[0]) {
    //     localStorage.setItem('token', response.data.data[0].token);
    //   }
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

  export const verifyOtpAction = createAsyncThunk(actionTypes.Verify_OTP, async ( params: varifyOtpParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-check-otp', params);
      console.log('response of otp', response);
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });