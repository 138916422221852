import axiosInstance from "../api/withJwtServer";
import { actionTypes } from "../actionTypes";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface KioskGateParams {
    kiosk_id: any;
    current_date:any
}

export const KioskGateAction = createAsyncThunk(actionTypes.Verify_OTP, async ( params: KioskGateParams, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/kiosk-get-gates-by-date', params);
      console.log('response of login', response);
  
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });