import React from "react";
import PageHeader from "../components/ValidationPageComponent/PageHeader";
import PageFooter from "../components/ValidationPageComponent/PageFooter";
import VerifyOTP from "../components/OTPVerification/VerifyOTP";

type Props = {};

const OtpVerification = (props: Props) => {
  return (
    <section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader />
            <VerifyOTP />
            <PageFooter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpVerification;
