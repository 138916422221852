import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS

const TimeSlotComponent = () => {
  const [activeSlot, setActiveSlot] = useState('All');

  const handleClick = (slot) => {
    setActiveSlot(slot);
  };

  return (
    <div className="d-flex justify-content-around p-3" style={{  border: '1px solid #D3D3D3',  flexWrap: 'nowrap', padding: '3px', borderRadius: 100, boxShadow: 'inset 0 0 10px #D3D3D3' }}>
      <button 
        onClick={() => handleClick('All')} 
        className={`btn ${activeSlot === 'All' ? 'btn-warning' : 'btn-light'} rounded-pill   px-sm-4`}
      >
        All
      </button>
      <button 
        onClick={() => handleClick('Morning')} 
        className={`btn ${activeSlot === 'Morning' ? 'btn-warning' : 'btn-light'} rounded-pill px-sm-4`}
      >
        Morning
      </button>
      <button 
        onClick={() => handleClick('Afternoon')} 
        className={`btn ${activeSlot === 'Afternoon' ? 'btn-warning' : 'btn-light'} rounded-pill px-sm-4`}
      >
        Afternoon
      </button>
    </div>
  );
}

export default TimeSlotComponent;