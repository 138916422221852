import React,{useState,useEffect} from "react";
import MobileNumber from "../components/MobileNumber/MobileNumber";
import PageHeader from "../components/ValidationPageComponent/PageHeader";
import PageFooter from "../components/ValidationPageComponent/PageFooter";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider , ErrorMessage} from 'formik';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../Redux-Store/store";
import {VerifyMobileAction} from "../Redux-Store/actions/VerifyNumberAction"
import { IoLogoWhatsapp } from "react-icons/io";
import { useDispatch } from "react-redux";

type Props = {};

const ValidateMobile = (props: Props) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)
 const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate()
  const dispatch :AppDispatch =useDispatch()

  const {state} = useLocation();

  console.log("STATE",state);


  interface FormValues {
    numberField: any;
  }

  const mobileValidationSchema= Yup.object({
    numberField: Yup.string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed') // Ensure only numbers are allowed
    .max(10, 'Mobile number must be at most 15 digits')
    .required('WhatsApp mobile number is required'),
  })




  const formik= useFormik<FormValues>({
    initialValues: {
      numberField: ''
    },
    validationSchema: mobileValidationSchema,
    onSubmit:(value)=>{
      console.log(value)
      navigate('/otpVerification',{state:state})
      // setSubmitLoader(true);
      dispatch(VerifyMobileAction({mobile_number:value.numberField}))
        .unwrap()
        .then((response:any) => {
          console.log('response in loginkk', response?.data?.status);
          if (response?.data?.status === 200) {
            setIsShow(true);
            // setError(false);
            // setOpen(!open);
            console.log('response', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              // setSubmitLoader(false);
              navigate('/dashboard/app', { replace: true });
            }, 1500);
          } else if (response?.data?.status === 404) {
            setIsShow(true);
            // setError(true);
            // setOpen(!open);
            console.log('responsehgjhj', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              // setSubmitLoader(false);
              // navigate('/dashboard/app', { replace: true });
            }, 1500);
          } else {
            console.log('else');
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
      // setTimeout(() => {}, 1000);
    }
  })

 
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader />
            <MobileNumber btnText={"Validate Mobile Number"} />          
            <PageFooter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValidateMobile;
