import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import BookingDetails from "../components/ConfirmTickets/BookingDetails";
import TicketCharges from "../components/ConfirmTickets/TicketCharges";

type Props = {};



const ConfirmTickts = (props: Props) => {
  const navigate= useNavigate()
  const location = useLocation()
console.log("location", location)
const bookingId = location.state.confirmTicketData.booking_id
console.log("bookingId",bookingId)


const visitors = location.state || [];
console.log("visitors",visitors.state)
  
  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12 border-bottom py-2">
          <header>
            <h3 className="heading">Confirm Tickets</h3>
            <Link to={"/DateSelection"} className="textSecondary">
              <MdOutlineArrowBack /> BACK TO DATE & GATE SELECTION
            </Link>
          </header>
        </div>
        <div className="col-md-6 pt-2 mt-1">
          <BookingDetails  visitorsName= {location.state.visitors}
          bookedSeats={location.state.bookedSeats}
          reg_no ={location.state.reg_no}
          bookingId={bookingId}
          />
        </div>
        <div className="col-md-6 pt-2 mt-1 border-start">
          <TicketCharges confirmTicketDataDetails={location.state.confirmTicketData} 
          bookedSeats={location.state.bookedSeats}
          
          />
        </div>
      </div>
    </section>
  );
};

export default ConfirmTickts;
