// reducers/verifyReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { verifyOtpAction } from '../actions/VerifyNumberAction';


interface VerifyOtpData{
    otpVerification: null;
    error: string | null;
    isLoading: boolean;
    messageOtp:string;
    is_error: boolean
    
}


const initialState: VerifyOtpData ={
    otpVerification: null,
    isLoading: false,
    error: '',
    messageOtp: '',
    is_error:false
}

const verifySlice = createSlice({
  name: 'otpVerification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtpAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtpAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.otpVerification = action.payload.data;
        state.messageOtp=action.payload.meta.message // Store response in state
        state.is_error= action.payload.meta.is_error
        console.log("state.otpVerification",   state.otpVerification)
        console.log("otpMessage", state.messageOtp)
      })
      .addCase(verifyOtpAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});



export default verifySlice.reducer;
