import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmDeleteModel(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancel Booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        Are you sure you want to cancel this booking?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button   style={{backgroundColor:"green",borderRadius: 5, borderColor:"green" }}onClick={props.onHide}>No</Button>
        <Button style={{backgroundColor:"red",borderColor:"red"}}onClick={props.handleDelete}>Yes,Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModel