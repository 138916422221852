import React from "react";
type DateSelectionProps = {
  day: string;
  date: string;
  isActive: any;
  onClick: () => void;
  style?: React.CSSProperties; 
};
const DateSelection = ({
  day,
  date,
  isActive,
  onClick,
}: DateSelectionProps) => {
  return (
    <>
      <div className="col text-center">
        <div
          className={`card shadow-sm dateBlock ${isActive ? "active" : ""}`}
          onClick={onClick}
        >
          <div className="card-body px-1">
            <h6 className="text-uppercase textSecondary">{day}</h6>
            <h6>
              <strong>{date}</strong>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateSelection;
